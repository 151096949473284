export const usePageAnalytics = (page: string): void => {
  const mixpanel = useMixpanel()
  const companyId = getCurrentDivisionId(false)
  const { data: user } = useAuth()

  const data: Record<string, string> = {}

  if (companyId) {
    data.Division = companyId
  }

  if (user.value?.email) {
    data['User Email'] = user.value.email
  }

  mixpanel.track_pageview({ page, ...data })
}
